<template>
  <div>
    <div
      class="content"
      v-if="
        !addVisiable && !detailsVisiable && !auditVisiable && !inventoryVisiable
      "
    >
      <div class="card" style="display: flex">
        <div class="left">
          <el-button type="primary" @click="handleAdd" icon="el-icon-plus"
            >新增盘点单</el-button
          >
          <el-button
            :loading="exportLoading"
            type="primary"
            plain
            @click="handleExport"
            icon="el-icon-upload2"
            >导出</el-button
          >
        </div>
        <div class="line"></div>
        <div class="middle" style="flex: 1">
          <Cond @onCond="handleCond" function_code="check" />
        </div>
        <div class="right" style="display: flex;">
          <TableConf function_code="check" @onRowList="handleRowList" />
          <PrintConf function_code="checkPrint" @onRowList="handlePrintRow" />
        </div>
      </div>
      <div class="table" v-if="rowList.length !== 0">
        <el-table
          :data="tableData"
          stripe
          height="700"
          @row-dblclick="handleDalclick"
        >
          <template v-for="(item, index) in rowList">
            <!-- <el-table-column
              v-if="item.filed_status"
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
              :filters="item.filed_status"
              :filter-method="filterHandler"
            >
            </el-table-column> -->
            <el-table-column
              align="center"
              :key="index"
              :prop="item.field_alias"
              :label="item.field_text"
              :width="item.t_width"
            >
            </el-table-column>
          </template>
          <el-table-column
            align="center"
            fixed="right"
            prop="operation"
            label="操作"
            width="180"
          >
            <template slot-scope="scope">
              <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleDetails($event, scope.row)"
                >
                  <el-option
                    label="盘点"
                    :value="1"
                    v-if="scope.row.check_status === 10"
                  ></el-option>
                  <el-option
                    label="审核"
                    :value="2"
                    v-if="scope.row.check_status === 20"
                  ></el-option>
                  <el-option label="详情" :value="3"></el-option>
                  <el-option
                    v-if="scope.row.check_status === 50"
                    label="审核失败原因"
                    :value="4"
                  ></el-option>
                  <el-option :value="5">打印</el-option>
                  <el-option :value="6" label="复制盘亏货号" v-if="scope.row.check_status === 20 || scope.row.check_status === 40 || scope.row.check_status === 50"  @click.native="copyCode(30)"></el-option>
                  <el-option :value="7" label="复制盘盈货号" v-if="scope.row.check_status === 20 || scope.row.check_status === 40 || scope.row.check_status === 50"  @click.native="copyCode(40)"></el-option>
                </el-select>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          :current-page="cond.page"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          :page-size="cond.limit"
          :page-sizes="[15, 30, 40, 50]"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </div>
    <LocalPrint :show="false" :printRows="printRows" :printData="printData" :title="title" :dialogVisible="localPrintVisible" @onClose="localPrintVisible = false" />
    <Add
      v-if="addVisiable"
      @hide="handleToInventory"
      @back="getList()"
      :type="type"
      :check_id="check_id"
    />
    <Inventory
      v-if="inventoryVisiable"
      @hide="getList()"
      :check_id="check_id"
    />
    <Audit v-if="auditVisiable" @hide="getList()" :check_id="check_id" />
    <Details v-if="detailsVisiable" @hide="getList('back')" :check_id="check_id" />
    <el-dialog
      :visible.sync="errorVisible"
      width="30%"
      title="审核失败原因"
      :destroy-on-close="true"
      :before-close="cancelError"
    >
      <div>{{ audit_remark }}</div>
    </el-dialog>
  </div>
</template>

<script>
// import { getList, getTakingPrintReq } from "@/api/goods/goodsStock/goodsInventory/index";
import { getList, detailsList, getTakingPrintReq } from "@/api/goods/goodsStock/goodsInventory/index";
import Add from "../add/Index.vue";
import Inventory from "../inventory/Index.vue";
import Audit from "../audit/Index.vue";
import Details from "../details/Index.vue";
import Cond from "@/components/cond/Index.vue";
import TableConf from "@/components/tableConf/Index.vue";
import PrintConf from "@/components/printConf/Index.vue";
import LocalPrint from "@/components/localPrint/Index.vue";

export default {
  data() {
    return {
      currRow: {},
      title: '盘点单',
      localPrintVisible: false,
      printData: {},
      printRows: [], // 打印的行
      copyString: '',
      currRow: {},
      exportLoading: false,
      tableData: [],
      total: 0,
      cond: {
        check_number: 0,
        check_status: 0,
        check_result: 0,
        page: 1,
        limit: 15,
      },
      type: "add",
      addVisiable: false,
      inventoryVisiable: false,
      detailsVisiable: false,
      auditVisiable: false,
      errorVisible: false,
      check_id: 0,
      audit_remark: "",
      rowList: [],
    };
  },
  components: {
    Add,
    Inventory,
    Audit,
    Details,
    Cond,
    TableConf,
    PrintConf,
    LocalPrint,
  },
  created() {
    this.getList();
  },
  methods: {
    getTakingPrint(cond) {
      getTakingPrintReq({ ...cond })
        .then((res) => {
          if (res.code === 1) {
            this.currRow.select = '';
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            res.data.top = [];
            const data = res.data.data;
            const arr1 = [
              { txt: '创建人', value: data.add_user_name, width: '24%' },
              { txt: '创建时间', value: data.create_time, width: '24%' },
              { txt: '盘点单号', value: data.check_number, width: '30%' },
            ];
            res.data.bottom = [];
            const arr2 = [
              { txt: '备注', value: data.audit_remark, width: '100%' },
            ];
            const arr3 = [
              { txt: '审核状态', value: data.check_status_text, width: '24%' },
              { txt: '审核人', value: data.audit_user_name, width: '24%' },
              { txt: '审核时间', value: data.audit_time ? data.audit_time : '', width: '30%' },
            ];
            res.data.top.push(arr1);
            res.data.bottom.push(arr2, arr3);
            // 计算合计
            this.printData = res.data;
            const sum = {
              index: '合计',
            };
            const decimal = {};
            this.printData.list.forEach((item) => {
              this.printRows.forEach((row) => {
                if (row.is_sum) {
                  if (!sum[row.field_alias]) {
                    sum[row.field_alias] = 0;
                  }
                  if (item[row.field_alias]) {
                    sum[row.field_alias] += Number(item[row.field_alias]);
                    if (!decimal[row.field_alias]) {
                      const arr = String(item[row.field_alias]).split('.');
                      if (arr.length === 2) {
                        decimal[row.field_alias] = arr[1].length;
                      } else {
                        decimal[row.field_alias] = 0;
                      }
                    }
                  }
                }
              });
            });
            // 需要保留多少位小数
            Object.keys(sum).forEach((item) => {
              if (item !== 'index') {
                sum[item] = sum[item].toFixed(decimal[item]);
              }
            });
            this.printData.list.push(sum);
            this.localPrintVisible = true;
          }
        })
        .catch(() => {
          this.currRow.select = '';
        });
    },
    handlePrintRow(rows) {
      this.printRows = rows;
    },
    copyCode(status) {
      detailsList({
        check_id: this.currRow.check_id,
        allot_status: 0,
        check_goods_result: status,
        goods_number: 0,
        page: 1,
        limit: 9999999,
        is_check: 10,
      })
        .then((res) => {
          if (res.code === 1) {
            this.currRow.select = '';
            if (res.data.list.length) {
              res.data.list.forEach((item) => {
                this.copyString += `${item.goods_number}\n`;
                this.$copyText(this.copyString);
              });
              this.$message.success('复制成功');
            } else {
              this.$message.warning('没有可复制的货号');
            }
          }
        });
    },
    /*
     *  获取列表数据
     */
    getList(type) {
      if (this.$route.params.type && !type) {
        if (this.$route.params.type === "detail") {
          this.detailsVisiable = true;
          this.check_id = this.$route.params.key;
        }
      } else {
        this.detailsVisiable = false;
      }
      this.addVisiable = false;
      this.auditVisiable = false;
      this.inventoryVisiable = false;
      getList({ ...this.cond }).then((res) => {
        if (res.code === 1) {
          res.data.list.map((item) => {
            for (var key in item) {
              if (!item[key]) {
                item[key] = "--";
              }
            }
            return item;
          });

          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  创建盘点单成功后，跳到盘点页面
     */
    handleToInventory(event) {
      this.addVisiable = false;
      this.check_id = event;
      this.inventoryVisiable = true;
    },
    filterHandler(value, row, column) {
      const check_result = {
        10: "正常",
        20: "盘亏",
        30: "盘盈",
      };
      const check_status = {
        10: "盘点中",
        20: "盘点完成",
        30: "盘点作废",
      };
      const { property } = column;
      return (
        row[property] ===
        (property === "check_status_text"
          ? check_status[value]
          : check_result[value])
      );
    },
    handleCond(cond) {
      this.cond = { ...cond, page: 1, limit: this.cond.limit };
      this.getList();
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    handleAdd() {
      this.type = "add";
      this.addVisiable = true;
    },
    /*
     * 导出
     */
    handleExport() {
      this.exportLoading = true;
      getList({ ...this.cond, export: 2 })
        .then((res) => {
          this.exportLoading = false;
          var filename = "货品盘点列表导出.csv";
          res = "\ufeff" + res;
          var blob = new Blob([res], { type: "application/vnd.ms-excel" });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            var urlCreator = window.URL || window.wibkitURL;
            var url = urlCreator.createObjectURL(blob);
            var a = document.createElement("a");
            a.download = filename;
            a.href = url;
            a.click();
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    /*
     * 分页操作
     */
    handleCurrentChange(val) {
      this.cond.page = val;
      getList({ ...this.cond }).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    handleSizeChange(val) {
      this.cond.limit = val;
      getList({ ...this.cond }).then((res) => {
        if (res.code === 1) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    cancelError() {
      this.errorVisible = false;
      this.getList();
    },
    handleDalclick(row) {
      this.audit_remark = row.audit_remark;
      this.check_id = row.check_id;
      this.detailsVisiable = true;
    },
    handleDetails(type, row) {
      this.currRow = row;
      this.audit_remark = row.audit_remark;
      this.check_id = row.check_id;
      this.currRow = row;
      if (type === 1) {
        this.inventoryVisiable = true;
      } else if (type === 2) {
        this.auditVisiable = true;
      } else if (type === 3) {
        this.detailsVisiable = true;
      } else if (type === 4) {
        this.errorVisible = true;
      } else if (type === 5) {
        this.getTakingPrint({
          check_id: row.check_id,
        });
      }
    },
  },
};
</script>
