<template>
  <div>
    <div class="content">
      <div class="card">
        <el-page-header @back="goBack" content="盘点信息"></el-page-header>
      </div>
    </div>
    <div class="newForm" style="padding-bottom: 20px">
      <div class="title">
        <div class="span"></div>
        盘点进度
        <div class="speedRight">
          <div class="prograss">
            <div class="proText">进度条</div>
            <div class="proNum" :style="{ width: +proNum + 'px' }">
              {{ originalProNum }}%
            </div>
            <div class="proLine"></div>
          </div>
        </div>
        <div class="speedBut" style="margin-left: 15px">
          <el-button
            type="info"
            style="width: 110px"
            :loading="auditLoading"
            @click="finishInvent(30)"
            >盘点作废</el-button
          >
          <el-button
            type="primary"
            :loading="auditLoading"
            style="width: 110px"
            @click="finishInvent(20)"
            >完成盘点</el-button
          >
        </div>
      </div>
      <div class="cutLine"></div>
      <div class="speed">
        <div class="box boxOne">
          <div style="width: 49%">
            <div class="speedNum">{{ info.goods_total_num || 0 }}</div>
            <div class="speedText">总货品数量</div>
          </div>
          <div class="line"></div>
          <div style="width: 49%">
            <div class="speedNum">{{ info.not_goods_num || 0 }}</div>
            <div class="speedText">不可盘数量</div>
          </div>
        </div>
        <div class="box boxSix">
          <div style="width: 49%">
            <div class="speedNum">
              {{ info.goods_total_num - info.not_goods_num || 0 }}
            </div>
            <div class="speedText">可盘数量(件)</div>
          </div>
          <div class="line"></div>
          <div style="width: 49%">
            <div class="speedNum">{{ info.practical_total_num || 0 }}</div>
            <div class="speedText">已盘数量(件)</div>
          </div>
        </div>
        <div class="box boxSix">
          <div style="width: 49%">
            <div class="speedNum">{{ info.original_gold_weight || 0 }}</div>
            <div class="speedText">可盘净金重(g)</div>
          </div>
          <div class="line"></div>
          <div style="width: 49%">
            <div class="speedNum">{{ info.practical_gold_weight || 0 }}</div>
            <div class="speedText">已盘净金重(g)</div>
          </div>
        </div>
        <div class="box boxThree">
          <div style="width: 49%">
            <div class="speedNum">{{ info.original_goods_weight || 0 }}</div>
            <div class="speedText">可盘总重(g)</div>
          </div>
          <div class="line"></div>
          <div style="width: 49%">
            <div class="speedNum">{{ info.practical_goods_weight  || 0 }}</div>
            <div class="speedText">已盘总重(g)</div>
          </div>
        </div>
        <div class="box boxThree">
          <div style="width: 49%">
            <div class="speedNum">{{ info.original_total_price || 0 }}</div>
            <div class="speedText">可盘总标签价(元)</div>
          </div>
          <div class="line"></div>
          <div style="width: 49%">
            <div class="speedNum">{{ info.practical_total_price || 0 }}</div>
            <div class="speedText">已盘总标签价(元)</div>
          </div>
        </div>
      </div>
      <div style="color:#999;margin-top:20px;margin-left:30px">盘点范围：{{info.search_text}}</div>
    </div>

    <div class="newForm">
      <div class="title">
        <div class="span"></div>
        扫描货品结果
      </div>
      <div class="cutLine"></div>
      <div class="result" style="padding-bottom: 20px">
        <div class="resultBox">
          <div class="resultInput">
            <el-input
              placeholder="扫描条码，鼠标光标请点这里"
              v-model="goodsValue"
              id="numberInput"
              @keyup.enter.native="handleKeydown"
            />
            <div>
              <el-button type="primary" @click="handleKeydown">提交</el-button>
            </div>
          </div>
          <div class="resutlImg">
            <img :src="goodsInfo.goods_pic ? goodsInfo.goods_pic : no_img" />
          </div>
        </div>

        <div class="resutlText">
          <el-row>
            <el-col :span="8">
              <div class="item">
                <div class="itemLeft">货号</div>
                <div class="itemRight">
                  {{ goodsInfo.goods_number || "--" }}
                </div>
              </div>
            </el-col>
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">仓库</div>
                <div class="itemRight">
                  {{ goodsInfo.warehouse_name || "--" }}
                </div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">品牌</div>
                <div class="itemRight">{{ goodsInfo[103] || "--" }}</div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">入库时间</div>
                <div class="itemRight">
                  {{ goodsInfo.create_time || "--" }}
                </div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">标签价</div>
                <div class="itemRight">
                  {{ goodsInfo.sale_price || "--" }}
                </div>
              </div></el-col
            >

            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">供应商</div>
                <div class="itemRight">
                  {{ goodsInfo.supplier_name || "--" }}
                </div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">入库单号</div>
                <div class="itemRight">
                  {{ goodsInfo.entry_number || "--" }}
                </div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">品类</div>
                <div class="itemRight">
                  {{ goodsInfo.goods_type_name || "--" }}
                </div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">RFID</div>
                <div class="itemRight">{{ goodsInfo.rfid_number || "--" }}</div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">入库备注</div>
                <div class="itemRight">{{ goodsInfo.entry_note || "--" }}</div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">款号</div>
                <div class="itemRight">
                  {{ goodsInfo.goods_style_number || "--" }}
                </div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">金重(g)</div>
                <div class="itemRight">{{ goodsInfo[104] || "--" }}</div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">证书类型</div>
                <div class="itemRight">{{ goodsInfo[111] || "--" }}</div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">款式</div>
                <div class="itemRight">{{ goodsInfo[101] || "--" }}</div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">总重(g)</div>
                <div class="itemRight">{{ goodsInfo[110] || "--" }}</div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">证书号</div>
                <div class="itemRight">{{ goodsInfo[112] || "--" }}</div>
              </div></el-col
            >
            <el-col :span="8"
              ><div class="item">
                <div class="itemLeft">材质成色</div>
                <div class="itemRight">{{ goodsInfo[100] || "--" }}</div>
              </div></el-col
            >
          </el-row>
        </div>
      </div>
    </div>
    <div class="newForm Intable">
      <el-tabs v-model="activeName" @tab-click="handleTab">
        <el-tab-pane label="可盘点货品列表" name="10">
          <el-radio-group
            v-model="tabPosition"
            style="margin-bottom: 30px"
            @change="handleTab"
          >
            <el-radio-button :label="0">全部</el-radio-button>
            <el-radio-button :label="20">已盘</el-radio-button>
            <el-radio-button :label="10">未盘</el-radio-button>
            <el-radio-button :label="40">盘盈</el-radio-button>
          </el-radio-group>
          <div style="float: right; margin-right: 20px">
            <el-input placeholder="请输入货号查询" v-model="goods_number">
              <template slot="append">
                <el-button type="primary" @click="handleSearch">搜索</el-button>
              </template>
            </el-input>
          </div>
          <el-table :data="goodsTableList" stripe :height="350">
            <el-table-column
              align="center"
              prop="goods_number"
              label="货号"
              width="200"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="goods_name"
              label="品名"
              width="200"
            >
              <template slot-scope="scope">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  {{ scope.row.goods_name || "--" }}
                  <template v-for="item in scope.row.goods_tag_pic">
                    <img :src="item" :key="item" style="margin-right: 5px" />
                  </template>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="goods_type_name"
              label="品类"
              width="200"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="goods_number_type"
              label="货品类型"
            >
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.goods_number_type === 10 ? "一码一货" : "一码多货"
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="warehouse_name"
              label="仓库"
              width="200"
            >
            </el-table-column>
            <el-table-column align="center" prop="sale_price" label="标签价">
            </el-table-column>
           <el-table-column
              align="center"
              prop="original_goods_weight"
              label="可盘总重(g)"
               width="160"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="practical_goods_weight"
              label="已盘总重(g)"
               width="160"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="original_gold_weight"
              label="可盘净金重(g)"
              width="180"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="original_total_num"
              label="可盘数量"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="practical_gold_weight"
              label="已盘净金重(g)"
              width="180"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="piece_gold_weight"
              label="单件净金重(g)"
              width="180"
            >
              <template slot-scope="scope">
                <div>
                  <div v-if="!scope.row.isWeightRewind">
                    {{ scope.row.piece_gold_weight || 0 }}
                    <el-button
                      type="text"
                      v-if="scope.row.check_goods_result_text !== '未盘点'"
                      @click="handleRewind(scope.row.check_goods_id)"
                      >修改</el-button
                    >
                  </div>
                  <div v-if="scope.row.isWeightRewind">
                    <el-input
                      style="width: 50%; margin-right: 8px"
                      v-model="scope.row.piece_gold_weight"
                    />
                    <el-button type="text" @click="update(scope.row, 1)"
                      >保存</el-button
                    >
                  </div>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              prop="practical_total_num"
              label="已盘数量"
              width="180"
            >
              <template slot-scope="scope">
                <div>
                  <div v-if="!scope.row.isNumRewind">
                    {{ scope.row.practical_total_num }}
                    <el-button
                      type="text"
                      v-if="
                        scope.row.goods_number_type === 20 &&
                        scope.row.check_goods_result_text !== '未盘点'
                      "
                      @click="handleNumRewind(scope.row.check_goods_id)"
                      >修改</el-button
                    >
                  </div>
                  <div v-if="scope.row.isNumRewind">
                    <el-input
                      style="width: 50%; margin-right: 8px"
                      v-model="scope.row.practical_total_num"
                    />
                    <el-button type="text" @click="update(scope.row, 1)"
                      >保存</el-button
                    >
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="gain_total_num"
              label="盈亏数量"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="gain_gold_weight"
              label="盈亏净金重(g)"
              width="180"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="check_goods_result_text"
              label="盘点结果"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.check_goods_result_text === '已盘' ||
                    scope.row.check_goods_result_text === '盘盈'
                  "
                  style="color: #21bc36"
                >
                  {{ scope.row.check_goods_result_text }}
                </div>
                <div v-else>{{ scope.row.check_goods_result_text }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="arr_tak_user" label="盘点人">
              <template slot-scope="scope">
                <span
                  v-for="(item, index) in scope.row.arr_tak_user"
                  :key="index"
                >
                  {{ item }}
                </span>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagina">
            <el-pagination
              :current-page="page"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              :page-size="$store.state.pageSizes[0]"
              :page-sizes="$store.state.pageSizes"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="不可盘点货品列表" name="20">
          <el-table :data="goodsTableList" stripe :height="350">
            <!-- <el-table-column align="center" prop="index" label="序号">
            </el-table-column> -->
            <el-table-column
              align="center"
              prop="goods_pic"
              label="图片"
              width="300"
            >
              <template v-slot="scope">
                <el-image
                  :src="scope.row.goods_pic"
                  :preview-src-list="[scope.row.goods_pic]"
                  class="image"
                >
                  >
                  <div slot="error" class="image">
                    <img :src="no_img" />
                  </div>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="goods_number"
              label="货号"
              width="180"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="goods_name"
              label="品名"
              width="180"
            >
              <template slot-scope="scope">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  {{ scope.row.goods_name || "--" }}
                  <template v-for="item in scope.row.goods_tag_pic">
                    <img :src="item" :key="item" style="margin-right: 5px" />
                  </template>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="goods_number_type"
              label="货品类型"
            >
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.goods_number_type === 10 ? "一码一货" : "一码多货"
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="warehouse_name" label="仓库">
            </el-table-column
            ><el-table-column
              align="center"
              prop="original_gold_weight"
              label="不可盘净金重(g)"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="original_total_num"
              label="不可盘数量"
            >
            </el-table-column>
          </el-table>
          <div class="pagina">
            <el-pagination
              :current-page="page"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              :page-size="$store.state.pageSizes[0]"
              :page-sizes="$store.state.pageSizes"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
      :visible.sync="auditVisiable"
      width="30%"
      title="填写货品信息"
      :destroy-on-close="true"
      :before-close="cancelAudit"
    >
      <el-form ref="auditForm" :model="auditForm" label-width="120px">
        <el-form-item label="货品数量(件)" prop="remark">
          <el-input
            :rows="2"
            placeholder="请输入货品数量"
            v-model="auditForm.practical_total_num"
          ></el-input>
        </el-form-item>
        <el-form-item label="货品净金重(g)" prop="remark">
          <el-input
            :rows="2"
            placeholder="请输入货品净金重(g)"
            v-model="auditForm.practical_gold_weight"
          ></el-input>
          <span style="color: #fd563a">单个货品的净金重(g)</span>
        </el-form-item>
        <div style="text-align: right">
          <el-button type="primary" @click="submitAudit()">提交</el-button>
          <el-button @click="cancelAudit">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import {
  getInfo,
  detailsList,
  handleTak,
  update,
  applyBy,
  lockGoodsList,
  getGoodsFind,
} from "@/api/goods/goodsStock/goodsInventory/index.js";
import goodsReq from "@/components/goods/chooseGoods/index.js";
import no_img from "../../../../../assets/images/no_img.png";
export default {
  data() {
    return {
      auditVisiable: false,
      activeName: "10",
      tabPosition: 0,
      page: 1,
      total: 0,
      page_num: 50,
      info: {},
      proNum: 0,
      originalProNum: 0,
      goodsValue: "",
      goodsInfo: {},
      goodsTableList: [],
      lockGoodsList: [],
      lockTotal: 0,
      practical_total_num: "",
      practical_gold_weight: "",
      auditForm: {},
      goods_number: "",
      allot_status: 0,
      no_img: no_img,
      auditLoading: false,
    };
  },
  props: {
    check_id: Number,
  },
  created() {
    this.getInfo();
    this.detailsList();
  },
  mounted() {
    this.handleNumberInputFocus();
  },
  methods: {
    /*
     *  查询盘点单详情
     */
    getInfo() {
      getInfo(this.check_id).then((res) => {
        if (res.code === 1) {
          this.info = res.data;
          // 计算盘点进度
          this.originalProNum = (
            (res.data.practical_total_num / res.data.original_total_num) *
            100
          ).toFixed(2);
          this.proNum =
            this.originalProNum > 100 ? 546 : 546 * (this.originalProNum / 100);
        }
      });
    },
    handleTab(tab, event) {
      if (tab.paneName === "20") {
        this.goodsTableList = [];
        this.page = 1;
        this.page_num = 50;
        this.detailsList();
      } else {
        this.goodsTableList = [];
        this.page = 1;
        this.page_num = 50;
        this.allot_status = 0;
        this.detailsList();
      }
    },
    /*
     *  根据条形码获取数据
     */
    handleKeydown() {
      // 如果有中文
      const pattern = new RegExp("[\u4E00-\u9FA5]+");
      if (pattern.test(this.goodsValue)) {
        this.$message.error("不能存在中文");
        return;
      }

      getGoodsFind({
        goods_number: this.goodsValue,
      })
        .then((res) => {
          if (res.code === 1) {
            if (res.data !== {}) {
              this.goodsInfo = res.data;
              this.goodsInfo.entry_create_time = moment(
                this.goodsInfo.entry_create_time * 1000
              ).format("YYYY-MM-DD HH:mm:ss");
            } else {
              this.$message({
                type: "error",
                message: "查询不到数据",
              });
            }
          }
        })
        .catch((res) => {
          // this.goodsValue = "";
        });
      this.handleTak();
    },
    handleTab(event) {
      this.page = 1;
      this.detailsList();
    },
    /*
     *  盘点单货品详情
     */
    detailsList() {
      const data = {
        check_id: this.check_id,
        allot_status: this.allot_status || 0,
        check_goods_result: this.tabPosition,
        goods_number: this.goods_number || 0,
        limit: this.page_num,
        page: this.page,
        is_check: this.activeName,
      };
      detailsList(data).then((res) => {
        if (res.code === 1) {
          res.data.list.forEach((item) => {
            item.isWeightRewind = false;
            item.isNumRewind = false;
            item.checkName = "";
            // item.arr_tak_user.forEach((items) => {
            //   if (items.realname) {
            //     item.checkName += items.realname || "--";
            //   }
            // });
            return item;
          });
          this.goodsTableList = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  盘点锁定货品详情
     */
    lockGoodList() {
      lockGoodsList({ check_id: this.check_id, page: 1, limit: 15 }).then(
        (res) => {
          if (res.code === 1) {
            this.lockGoodsList = res.data.list;
            this.lockTotal = res.data.total;
          }
        }
      );
    },
    handleLockCurrentChange(val) {
      lockGoodsList({ check_id: this.check_id, page: val, limit: 15 }).then(
        (res) => {
          if (res.code === 1) {
            this.lockGoodsList = res.data.list;
            this.lockTotal = res.data.total;
          }
        }
      );
    },
    /*
     *  盘点单货品分页操作
     */
    handleCurrentChange(val) {
      this.page = val;
      this.detailsList();
    },
    handleSizeChange(val) {
      this.page = 1;
      this.page_num = val;
      this.detailsList();
    },
    cancelAudit() {
      this.auditVisiable = false;
      this.goodsValue = "";
      this.auditForm = {};
    },
    /*
     *  返回
     */
    goBack() {
      this.$emit("hide");
    },
    /*
     *  提交货品信息
     */
    handleTak() {
      const data = {
        check_id: this.check_id,
        goods_number: this.goodsValue,
      };
      if (this.goodsInfo !== "{}") {
        if (this.goodsInfo.goods_number_type === 10) {
          data.practical_gold_weight = this.goodsInfo.goods_gold_weight || 0;
          //  data.practical_total_price = this.goodsInfo.sale_price;
          data.practical_total_num = this.goodsInfo.num;
        } else if (this.goodsInfo.goods_number_type === 20) {
          this.auditVisiable = true;
        }
      }
      this.handleCommitTak(data);
    },
    submitAudit() {
      const data = {
        goods_number: this.goodsValue,
      };
      data.practical_gold_weight = this.auditForm.practical_gold_weight;
      data.practical_total_price = this.goodsInfo.sale_price;
      data.practical_total_num = this.auditForm.practical_total_num;
      data.is_adopt = 10;
      this.handleCommitTak(data);
    },
    handleCommitTak(data) {
      data.check_id = this.check_id;
      handleTak(data)
        .then((res) => {
          if (res.code === 1) {
            this.$message({
              type: "success",
              message: "提交成功!",
            });
            this.goodsValue = "";
            this.auditVisiable = false;
            this.tabPosition = 20;
            this.auditForm = {};
            this.page = 1;
            this.detailsList();
            this.getInfo();
          } else if (res.code === 206) {
            this.auditVisiable = true;
          } else {
            this.goodsValue = "";
          }
        })
        .catch((res) => {
          if (res.code === 206) {
            this.auditVisiable = true;
          }
        });
    },

    /*
     *  重盘货品
     */
    handleRewind(id) {
      this.goodsTableList = this.goodsTableList.map((item) => {
        if (item.check_goods_id === id) {
          item.isWeightRewind = true;
        }
        return item;
      });
    },
    handleNumRewind(id) {
      this.goodsTableList = this.goodsTableList.map((item) => {
        if (item.check_goods_id === id) {
          item.isNumRewind = true;
        }
        return item;
      });
    },
    // 货号input聚焦
    handleNumberInputFocus() {
      const numberInputDom = document.getElementById("numberInput");
      numberInputDom.focus();
    },
    /*
     * 完成盘点
     */
    finishInvent(status) {
      this.auditLoading = true;
      var data = {
        check_id: this.check_id,
        check_status: status,
      };
      this.$confirm("此操作是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          applyBy(data).then((res) => {
            if (res.code === 1) {
              this.auditLoading = false;
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.goBack();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
          this.auditLoading = false;
        });
    },
    /*
     *  重置货品
     */
    update(data, type) {
      const info = {
        practical_total_num: data.practical_total_num,
        check_goods_id: data.check_goods_id,
        practical_gold_weight: data.piece_gold_weight,
      };

      update(info).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "保存成功!",
          });
          this.detailsList();
          this.getInfo();
        }
      });
    },

    /*
     *  检索货品
     */
    handleSearch() {
      this.detailsList();
    },
  },
};
</script>

<style scoped lang="less">
/deep/ .el-input__inner {
  height: 33px;
  line-height: 33px;
}
/deep/ .title {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  height: 60px;
  line-height: 60px;

  .span {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
}
/deep/ .speed {
  margin-left: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  .line {
    margin: auto 0;
    width: 2px;
    height: 39px;
    background: #f5f6fa;
  }
}

/deep/ .newly {
  padding: 20px;
}
/deep/ .box {
  display: flex;
  margin-left: 10px;
  width: 19%;
  height: 90px;
  border-radius: 6px;
  color: white;
  text-align: center;
}
/deep/ .boxOne {
  background: linear-gradient(90deg, #5aabfe, #597bf7);
}
/deep/ .boxTwo {
  background: linear-gradient(90deg, #5aabfe, #597bf7);
}
/deep/ .boxThree {
  background: linear-gradient(90deg, #fd7e5d, #ff628a);
}
/deep/ .boxFour {
  background: linear-gradient(90deg, #fd7e5d, #ff628a);
}
/deep/ .boxFive {
  background: linear-gradient(
    90deg,
    rgba(14, 205, 186, 0.98),
    rgba(0, 190, 244, 0.98)
  );
}
/deep/ .tagName {
  width: auto;
  height: 30px;
  background: #fd563a;
  border-radius: 4px;
  line-height: 30px;
  color: white;
  font-size: 12px;
}
/deep/ .boxSix {
  background: linear-gradient(
    90deg,
    rgba(14, 205, 186, 0.98),
    rgba(0, 190, 244, 0.98)
  );
}
/deep/ .boxSeven {
  background: linear-gradient(
    90deg,
    rgba(56, 89, 236, 0.98),
    rgba(28, 156, 216, 0.98)
  );
}

// /deep/.el-input__inner {
//   border-color: white !important;
// }

.speedNum {
  margin-top: 15px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
}
 .speedText {
  font-size: 16px;
}

 .speedRight {
  margin: auto 10px auto auto;
}

 .prograss {
  position: relative;
  display: flex;
  flex-direction: row;
}

 .proText {
  line-height: 30px;
}
.proNum {
  position: absolute;
  top: 1px;
  left: 53px;
  height: 30px;
  background: linear-gradient(90deg, #5aabfe, #597bf7);
  border-radius: 15px;
  text-align: right;
  line-height: 30px;
  color: black;
  font-size: 15px;
}

.speedBut {
  width: 300px;
}
 .proLine {
  margin-left: 10px;
  width: 546px;
  height: 30px;
  background: #f5f6fa;
  border: 1px solid #ddd;
  border-radius: 15px;
}
/deep/ .cutLine {
  width: 100%;
  height: 1px;
  background: #f5f6fa;
}
/deep/ .result {
  display: flex;
  margin: 20px 0 0 20px;
}
.resultBox {
  padding: 10px;
  width: 300px;
}
.resultInput {
  display: flex;
  margin-bottom: 5px;
  width: 300px;
  background: #ffffff;
}

.resutlImg {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 300px;
  height: 200px;
  border: 1px solid #ddd;
  border-radius: 6px;

  img {
    height: 100%;
  }
}
.Intable {
  padding: 20px;
}
.resutlText {
  width: 80%;
  margin-left: 30px;
  padding: 10px;
}
.item {
  display: flex;
  margin-left: 30px;
  font-size: 14px;

  .itemLeft {
    width: 35%;
    height: 45px;
    color: #909399;
  }
  .itemRight {
    width: 55%;
  }
}
.image {
  width: 54px;
  height: 54px;
  margin: 5px auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
