<template>
  <div>
    <div class="content">
      <div class="card">
        <el-page-header @back="goBack" content="添加盘点信息"></el-page-header>
      </div>
    </div>
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <div class="newForm" style="padding-bottom: 20px">
        <div class="title">
          <div class="span"></div>
          盘点单信息
        </div>
        <div class="cutLine"></div>
        <div class="form" v-if="firstStep === true">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item
                label="盘点标题"
                :label-width="formLabelWidth"
                prop="title"
              >
                <el-input
                  v-model="form.title"
                  autocomplete="off"
                  style="width: 375px"
                  placeholder="请输入盘点标题"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="所属品类" :label-width="formLabelWidth">
                <el-select
                  clearable
                  multiple
                  collapse-tags
                  v-model="form.goods_type_id"
                  placeholder="请选择所属品类"
                  style="width: 375px"
                  @change="handleGoodsType"
                >
                  <template>
                    <el-option-group label="商品分类">
                      <el-option
                        v-for="item in goods_typeOne_list"
                        :label="item.goods_type_name"
                        :value="item.goods_type_id"
                        :key="item.goods_type_id"
                        :disabled="item.disabled"
                      >
                        <span :style="{ marginLeft: item.span * 8 + 'px' }">{{
                          item.goods_type_name
                        }}</span>
                      </el-option>
                    </el-option-group>
                    <el-option-group label="旧料分类">
                      <el-option
                        v-for="item in goods_typeTwo_list"
                        :label="item.goods_type_name"
                        :value="item.goods_type_id"
                        :key="item.goods_type_id"
                        :disabled="item.disabled"
                      >
                        <span :style="{ marginLeft: item.span * 8 + 'px' }">{{
                          item.goods_type_name
                        }}</span>
                      </el-option>
                    </el-option-group>
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="盘点人"
                :label-width="formLabelWidth"
                prop="check_store_user_ids"
              >
                <el-select
                  clearable
                  multiple
                  collapse-tags
                  v-model="form.check_store_user_ids"
                  placeholder="请选择盘点人"
                  style="width: 375px"
                >
                  <template v-for="(item, index) in selectUserList">
                    <el-option
                      :label="item.realname"
                      :value="item.store_user_id"
                      :key="index"
                    ></el-option>
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label="选择仓库"
                :label-width="formLabelWidth"
                prop="warehouse_id"
              >
                <el-select
                  v-model="form.warehouse_id"
                  placeholder="请选择仓库"
                  style="width: 375px"
                  clearable
                  multiple
                  collapse-tags
                >
                  <template v-for="(item, index) in warehouseList">
                    <el-option
                      :label="item.warehouse_name"
                      :value="item.warehouse_id"
                      :key="index"
                    ></el-option>
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
            <div v-for="(item, index) in selectList" :key="index">
              <el-col :span="8">
                <el-form-item
                  :label="item.attr_name"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    v-model="form[item.attr_fields]"
                    v-if="item.search_type === 10"
                    :placeholder="'请输入' + item.attr_name"
                    style="width: 375px"
                    clearable
                  />
                  <el-select
                    clearable
                    v-else-if="item.search_type === 20"
                    v-model="form[item.attr_fields]"
                    :placeholder="'请选择' + item.attr_name"
                  multiple
                  collapse-tags
                    style="width: 375px"
                  >
                    <template v-for="(i, indexs) in item.val_list">
                      <el-option
                        :label="i.attr_value"
                        :value="i.attr_value"
                        :key="indexs"
                      ></el-option>
                    </template>
                  </el-select>
                  <template v-if="item.search_type === 30">
                    <el-input
                      :placeholder="'请输入' + item.attr_name + '范围'"
                      v-model="form[item.field1]"
                      style="width: 170px; margin-right: 10px"
                      clearable
                    ></el-input>
                    <span style="margin-right: 10px">至</span>
                    <el-input
                      :placeholder="'请输入' + item.attr_name + '范围'"
                      v-model="form[item.field2]"
                      style="width: 170px; margin-right: 10px"
                      clearable
                    ></el-input>
                  </template>
                </el-form-item>
              </el-col>
            </div>

            <!-- <el-col :span="8" v-if="textureList.length !== 0">
              <el-form-item
                label="材质成色"
                :label-width="formLabelWidth"
                prop="hierarchy_connection"
              >
                <el-select
                  clearable
                  v-model="form.texture_id"
                  placeholder="请选择材质成色"
                  style="width: 375px"
                >
                  <template v-for="(item, index) in textureList">
                    <el-option
                      :label="item.attr_value"
                      :value="item.attr_value_id"
                      :key="index"
                    ></el-option>
                  </template>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8" v-if="brandList.length !== 0">
              <el-form-item
                label="品牌"
                :label-width="formLabelWidth"
                prop="hierarchy_connection"
              >
                <el-select
                  clearable
                  v-model="form.brand_id"
                  placeholder="请选择品牌"
                  style="width: 375px"
                >
                  <template v-for="(item, index) in brandList">
                    <el-option
                      :label="item.attr_value"
                      :value="item.attr_value_id"
                      :key="index"
                    ></el-option>
                  </template>
                </el-select>
              </el-form-item>
            </el-col> -->
            <el-col :span="8">
              <el-form-item label="备注" :label-width="formLabelWidth">
                <el-input
                  v-model="form.remark"
                  type="textarea"
                  autocomplete="off"
                  style="width: 375px"
                  placeholder="请输入备注信息"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div v-if="secondStep === true">
          <div class="top">
            <div class="item">
              <div class="item-left">标题</div>
              <div class="item-right">{{ form.title }}</div>
            </div>
            <div class="item">
              <div class="item-left">可盘净金重</div>
              <div class="item-right">{{ stockTotal }}</div>
            </div>
            <div class="item">
              <div class="item-left">创建人</div>
              <div class="item-right">{{ userInfo.realname }}</div>
            </div>
            <div class="item">
              <div class="item-left">货品数量</div>
              <div class="item-right">
                {{ goodsTotal }}
              </div>
            </div>

            <div class="item">
              <div class="item-left">可盘数量</div>
              <div class="item-right">{{ checkGoodsTotal }}</div>
            </div>
            <div class="item">
              <div class="item-left">不可盘数量</div>
              <div class="item-right">{{ noCheckGoodsTotal }}</div>
            </div>

            <div class="item">
              <div class="item-left">盘点人</div>
              <div class="item-right">
                <div v-for="(item, index) in userInfoArr" :key="index">
                  {{ item }}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="item-left">仓库</div>
              <div class="item-right">
                <div v-for="(item, index) in warehouseArr" :key="index">
                  {{ item }}
                </div>
              </div>
            </div>
            <div class="item">
              <div class="item-left">备注</div>
              <div class="item-right">{{ form.remark }}</div>
            </div>
          </div>
          <div class="toolTitle">
            <div
              class="box"
              v-for="(item, index) in tabs"
              :key="index"
              :style="selectTool === item ? 'color: #2791FF' : ''"
              @click="changeTool(item)"
            >
              {{ item }}
              <div class="span" v-if="selectTool === item"></div>
            </div>
          </div>
          <el-table
            ref="plTable"
            :data="inventoryTableList"
            v-loading="inventoryLoading"
            v-if="selectTool === '可盘货品'"
            :height="500"
          >
            <el-table-column
              align="center"
              prop="goods_number"
              label="货号"
            ></el-table-column>
            <el-table-column align="center" prop="goods_name" label="品名"
              ><template slot-scope="scope">
                <div style="display: flex; align-items: center">
                  {{ scope.row.goods_name || "--" }}
                  <div
                    v-if="scope.row.goods_tag_pic.length !== 0"
                    style="display: flex; margin-left: 5px"
                  >
                    <template v-for="item in scope.row.goods_tag_pic">
                      <img :src="item" :key="item" style="margin-right: 5px" />
                    </template>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="goods_type_name"
              label="品类"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="goods_number_type_name"
              label="货品类型"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="warehouse_name"
              label="仓库"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="104"
              label="可盘净金重"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="can_check_pcs"
              label="可盘数量"
            ></el-table-column>
          </el-table>
          <div class="pagina" v-if="selectTool === '可盘货品'">
            <el-pagination
              background
              :current-page="page"
              @size-change="handleSizeChange"
              @current-change="handleCurrentPage"
              layout="total, sizes, prev, pager, next, jumper"
              :page-size="$store.state.pageSizes[0]"
              :page-sizes="$store.state.pageSizes"
              :total="inVentTotal"
            >
            </el-pagination>
          </div>
          <el-table
            ref="plTable"
            :data="noInventoryTableList"
            v-loading="inventoryLoading"
            v-if="selectTool === '不可盘货品'"
            :height="500"
          >
            <el-table-column
              align="center"
              prop="goods_number"
              label="货号"
            ></el-table-column>
            <el-table-column align="center" prop="goods_name" label="品名"
              ><template slot-scope="scope">
                <div style="display: flex; align-items: center">
                  {{ scope.row.goods_name || "--" }}
                  <div
                    v-if="scope.row.goods_tag_pic.length !== 0"
                    style="display: flex; margin-left: 5px"
                  >
                    <template v-for="item in scope.row.goods_tag_pic">
                      <img :src="item" :key="item" style="margin-right: 5px" />
                    </template>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="goods_type_name"
              label="品类"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="goods_number_type_name"
              label="货品类型"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="warehouse_name"
              label="仓库"
            ></el-table-column>
            <!-- <el-table-column
              align="center"
              prop="104"
              label="应盘金重"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="stock"
              label="应盘数量"
            ></el-table-column> -->
          </el-table>
          <div class="pagina" v-if="selectTool === '不可盘货品'">
            <el-pagination
              background
              :current-page="page"
              @size-change="handleSizeChange"
              @current-change="handleCurrentPage"
              layout="total, sizes, prev, pager, next, jumper"
              :page-size="$store.state.pageSizes[0]"
              :page-sizes="$store.state.pageSizes"
              :total="noInVentTotal"
            >
            </el-pagination>
          </div>
        </div>
        <div
          style="
            width: 300px;
            margin: 20px auto 20px auto;
            display: flex;
            flex-desition: row;
          "
          v-if="firstStep === true"
        >
          <el-button type="primary" @click="nextStep('ruleForm')"
            >下一步</el-button
          >
        </div>
        <div
          style="
            width: 300px;
            margin: 20px auto 20px auto;
            display: flex;
            flex-desition: row;
          "
          v-if="secondStep === true"
        >
          <el-button @click="lastStep()">上一步</el-button>
          <el-button
            type="primary"
            :loading="loading"
            :disabled="inventoryLoading"
            @click="submit('ruleForm')"
            >开始盘点</el-button
          >
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getListReq } from "@/api/goods/goodsConfig/classify";
import {
  selectUser,
  getTakeGoodsItem,
  create,
  getWarehouseList,
} from "@/api/goods/goodsStock/goodsInventory/index.js";
import { getList } from "@/api/goods/goodsStock/goodsDetailedlist/index.js";
import { getEmployeeReq } from "@/api/order/offlineOrder/add";
import storage from "good-storage";

export default {
  data() {
    return {
      form: {
        goods_type_id: [],
        check_store_user_ids: [],
      },
      userInfoArr: [],
      warehouseArr: [],
      formLabelWidth: "150px",
      tabs: ["可盘货品", "不可盘货品"],
      selectTool: "可盘货品",
      goods_typeOne_list: [],
      goods_typeTwo_list: [],
      loading: false,
      hierarchy_list: [],
      selectUserList: [],
      selectList: [],
      textureList: [],
      brandList: [],
      userInfo: {},
      firstStep: true,
      secondStep: false,
      warehouseList: [],
      inventoryTableList: [], // 可盘点数据
      noInventoryTableList: [], // 不可盘点数据
      stockTotal: 0, // 可盘净金重
      page: 1,
      limit: 50,
      total: 0,
      checkGoodsTotal: 0,
      noCheckGoodsTotal: 0,
      inVentTotal: 0,
      goodsTotal: 0,
      noInVentTotal: 0,
      inventoryLoading: false,
      rules: {
        goods_type_id: [
          { required: true, message: "请选择盘点品类", trigger: "change" },
        ],
        check_store_user_ids: [
          { required: true, message: "请选择盘点人", trigger: "change" },
        ],
        title: [{ required: true, message: "请输入盘点标题", trigger: "blur" }],
      },
    };
  },
  created() {
    this.userInfo = storage.get("userInfo");
    this.getTypeOneList();
    this.getTypeTwoList();
    this.selectUser();
    this.getWarehouseList();
  },
  methods: {
    /*
     *  获取品类下拉数据
     */
    getTypeOneList() {
      const data = {
        type: [10],
      };
      getListReq(data).then((res) => {
        if (res.code === 1) {
          // 如果有数据，当前不是新增并且获取对应详情
          if (res.data.length) {
            this.goods_typeOne_list = this.handleArr(res.data);
          } else {
            this.isAdd = true;
          }
        }
      });
    },
    getTypeTwoList() {
      const data = {
        type: [20],
      };
      getListReq(data).then((res) => {
        if (res.code === 1) {
          // 如果有数据，当前不是新增并且获取对应详情
          if (res.data.length) {
            this.goods_typeTwo_list = this.handleArr(res.data);
          } else {
            this.isAdd = true;
          }
        }
      });
    },
    /*
     *  上一步
     */
    lastStep() {
      this.firstStep = true;
      this.secondStep = false;
    },
    /*
     *  下一步
     */
    nextStep(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.firstStep = false;
          this.secondStep = true;
          let checkUser = [];
          let wareHouse = [];
          this.form.check_store_user_ids.forEach((item) => {
            this.selectUserList.forEach((arr) => {
              if (item === arr.store_user_id) {
                checkUser.push(arr.realname);
              }
            });
          });
          this.form.warehouse_id.forEach((item) => {
            this.warehouseList.forEach((arr) => {
              if (item === arr.warehouse_id) {
                wareHouse.push(arr.warehouse_name);
              }
            });
          });
          this.userInfoArr = checkUser;
          this.warehouseArr = wareHouse;
          this.getInventList();
          this.getNoInventList();
        }
      });
    },
    /*
     *  分页操作
     */
    handleCurrentPage(page) {
      this.page = page;
      if (this.selectTool === "可盘货品") {
        this.getInventList();
      } else {
        this.getNoInventList();
      }
    },
    /*
     *  页数操作
     */
    handleSizeChange(limit) {
      this.limit = limit;
      if (this.selectTool === "可盘货品") {
        this.getInventList();
      } else {
        this.getNoInventList();
      }
    },
    /*
     *  获取货品清单数据（可盘）
     */
    getInventList() {
      let cond = {
        ...this.form,
      };
      delete cond.title;
      delete cond.check_store_user_ids;
      delete cond.remark;

      this.inventoryTableList = [];
      this.noInventoryTableList = [];

      this.inventoryLoading = true;
      const info = {
        ...cond,
        query_type: "check_goods",
        page: this.page,
        page_num: this.limit,
        isCheck: 10,
      };
      getList(info).then((res) => {
        if (res.code === 1) {
          this.inventoryTableList = res.data.list;
          this.total = res.data.total;
          this.inVentTotal = res.data.total;
          this.checkGoodsTotal = res.data.goodsTotal.checkGoodsTotal;
          this.goodsTotal = res.data.goodsTotal.goodsTotal;
          this.stockTotal = res.data.goodsTotal.goldweight;
          this.inventoryLoading = false;
        }
      });
    },
    /*
     *  获取货品清单数据（不可盘）
     */
    getNoInventList() {
      let cond = {
        ...this.form,
      };
      delete cond.title;
      delete cond.check_store_user_ids;
      delete cond.remark;

      this.inventoryTableList = [];
      this.noInventoryTableList = [];

      this.inventoryLoading = true;
      const info = {
        ...cond,
        query_type: "check_goods",
        page: this.page,
        page_num: this.limit,
        isCheck: 20,
      };
      getList(info).then((res) => {
        if (res.code === 1) {
          this.noInventoryTableList = res.data.list;
          this.total = res.data.total;
          this.noInVentTotal = res.data.total;
          this.noCheckGoodsTotal = res.data.goodsTotal.checkGoodsTotal;
          this.inventoryLoading = false;
        }
      });
    },
    /*
     *  所选品类
     *  根据需求，选择一个大类，需将其小类默认勾选
     */
    handleGoodsType(e) {
      const list = this.goods_typeOne_list.concat(this.goods_typeTwo_list);
      list.forEach((item) => {
        if (item.goods_type_id === Number(e)) {
          if (item.children) {
            this.fol(item.children);
          }
        }
      });
      const styleData = {
        goods_type_id: this.form.goods_type_id,
      };
      getTakeGoodsItem(styleData).then((res) => {
        if (res.code === 1) {
          this.selectList = res.data;
          this.selectList.map((item, index) => {
            if (item.search_type === 30) {
              item.field1 = `${item.attr_fields}array1`;
              item.field2 = `${item.attr_fields}array2`;
            }
            return item;
          });
          console.log(this.selectList);
        }
      });
    },
    /*
     *  遍历获取品类子数据
     */
    fol(arr) {
      arr.forEach((item) => {
        this.form.goods_type_id.push(item.goods_type_id);
        if (item.children) {
          this.fol(item.children);
        }
      });
    },
    /*
     *  仓库下拉数据
     */
    getWarehouseList() {
      getWarehouseList().then((res) => {
        if (res.code === 1) {
          this.warehouseList = res.data;
        }
      });
    },
    /*
     *  提交审核
     */
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const info = { ...this.form };
          let errorKey = [];
          /*
           *   遍历数据，将针对范围的大小
           */
          for (let key in info) {
            if (key.indexOf("array") > -1) {
              let newKey = key.substr(0, key.length - 6);
              if (!info[newKey]) {
                info[newKey] = [];
                info[newKey][0] = info[key];
              } else {
                info[newKey][1] = info[key];
              }
              delete info[key];
              if (Number(info[newKey][0]) > Number(info[newKey][1])) {
                // this.$message.warning('范围搜索前面的值必须不小于后面的值');
                errorKey.push(newKey);
              }
            }
          }
          // 校验范围大小
          if (errorKey.length) {
            for (let i = 0; i < this.selectList.length; i += 1) {
              const item = this.selectList[i];
              if (Number(errorKey[0]) === Number(item.attr_fields)) {
                this.$message.warning(
                  `${item.attr_name}范围 “前面的值” 不得大于 “后面的值”`
                );
                return;
              }
            }
          }
          /*
           *  盘点人员字段拼接
           */
          if (info.check_store_user_ids.length !== 0) {
            info.check_store_user_ids = info.check_store_user_ids.join(",");
          }
          this.loading = true;
          create(info)
            .then((res) => {
              if (res.code === 1) {
                this.loading = false;
                this.$emit("hide", res.data.check_id);
              }
            })
            .catch(() => {
              this.loading = false;

              info.check_store_user_ids = info.check_store_user_ids
                .split(",")
                .map((item) => {
                  return Number(item);
                });
            });
        }
      });
    },
    handleArr(arr) {
      const newArr = [];
      function poling(arr, span) {
        arr.forEach((item) => {
          if (span || span === 0) {
            item.span = span + 1;
            item.goods_type_name = "├─" + item.goods_type_name;
          } else {
            item.span = 0;
          }
          newArr.push(item);
          if (item.children) {
            poling(item.children, item.span);
          }
        });
      }
      // 深复制
      const aArr = JSON.parse(JSON.stringify(arr));
      poling(aArr);
      return newArr;
    },

    /*
     *  获取盘点人数据
     */
    selectUser() {
      // const data = {
      //   status: 10,
      //   hierarchy_id: this.userInfo.hierarchy_id,
      // };
      getEmployeeReq().then((res) => {
        if (res.code === 1) {
          this.selectUserList = res.data;
        }
      });
    },
    /*
     *  返回
     */
    goBack() {
      this.$emit("back");
    },
    changeTool(item) {
      this.page = 1;
      this.limit = 15;
      if (item === "可盘货品") {
        this.getInventList();
      } else {
        this.getNoInventList();
      }
      this.selectTool = item;
    },
  },
};
</script>

<style scoped lang="less">
.toolTitle {
  display: flex;
  margin: 10px auto 0px auto;
  width: 100%;
  height: 50px;
  background: linear-gradient(#f3f9ff 1%, #ffffff 100%);
  border: 1px solid #e8e8e8;
  border-bottom: none;

  .box {
    width: 180px;
    text-align: center;
    line-height: 50px;
    font-size: 16px;
    color: #909399;
    .span {
      margin: -3px auto 0 auto;
      width: 20px;
      height: 4px;
      background: #5493f5;
      border-radius: 2px;
    }
  }
}
/deep/ .title {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  width: 100%;
  height: 60px;
  line-height: 60px;

  .span {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
}
/deep/ .newly {
  padding: 20px;
  width: 100%;
}
/deep/ .cutLine {
  width: 100%;
  height: 1px;
  background: #f5f6fa;
}
/deep/ .form {
  height: 100%;
  margin-top: 20px;
  padding-left: 20px;
}
.top {
  display: flex;
  padding: 0px 50px 10px 50px;
  background: white;
  border-radius: 6px;
  flex-wrap: wrap;
  font-size: 14px;
  margin-top: 20px;
  .item {
    display: flex;
    margin-bottom: 20px;
    width: 25%;
    &-left {
      width: 100px;
      color: #909399;
      font-size: 16px;
      position: relative;
      &::before {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #2791ff;
        position: absolute;
        top: 10px;
        left: -15px;
      }
    }
    &-right {
      color: #565656;
      font-size: 16px;
    }
  }
}
</style>
