<template>
  <div>
    <div class="content">
      <div class="card">
        <el-page-header @back="goBack" content="盘点详情"></el-page-header>
      </div>
    </div>
    <div style="background: white; margin-top: 15px">
      <div class="btn">
        <el-button type="primary" plain @click="handlePrint">打印</el-button>
        <el-button
          type="primary"
          plain
          @click="handleExport"
          icon="el-icon-upload2"
          :loading="exportLoading"
          >导出</el-button
        >
      </div>
      <div class="top">
        <div class="item" v-for="item in arr" :key="item.id">
          <div class="item-left">{{ item.name }}</div>
          <div class="item-right">{{ item.value }}</div>
        </div>
        <div class="item" v-if="info.check_status === 50">
          <div class="item-left">审核失败原因</div>
          <div class="item-right">{{ info.audit_remark }}</div>
        </div>
      </div>
    </div>
    <div class="newForm Intable">
      <el-tabs v-model="activeName" @tab-click="handleTab">
        <el-tab-pane label="可盘点货品列表" name="10">
          <el-radio-group
            v-model="tabPosition"
            style="margin-bottom: 30px"
            @change="detailsListInfo()"
          >
            <el-radio-button :label="0">全部</el-radio-button>
            <el-radio-button :label="20">已盘</el-radio-button>
            <el-radio-button
              :label="10"
              v-if="
                info.check_status_text === '盘点中' ||
                info.check_status_text === '盘点作废'
              "
              >未盘</el-radio-button
            >
            <el-radio-button
              :label="30"
              v-if="
                info.check_status_text !== '盘点中' &&
                info.check_status_text !== '盘点作废'
              "
              >盘亏</el-radio-button
            >
            <el-radio-button :label="40">盘盈</el-radio-button>
          </el-radio-group>
          <div style="float: right; margin-right: 20px; display: flex">
            <el-button type="primary" @click.native="copyCode(30)"
              >复制盘亏货号</el-button
            >
            <el-button
              type="primary"
              style="margin-right: 10px"
              @click.native="copyCode(40)"
              >复制盘盈货号</el-button
            >
            <el-input
              placeholder="请输入货号查询"
              clearable
              v-model="goods_number"
            >
              <template slot="append">
                <el-button type="primary" @click="handleSearch">搜索</el-button>
              </template>
            </el-input>
          </div>
          <el-table :data="goodsTableList" stripe :height="350">
            <el-table-column
              align="center"
              prop="goods_number"
              label="货号"
              width="200"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="goods_name"
              label="品名"
              width="200"
            >
              <template slot-scope="scope">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  {{ scope.row.goods_name || "--" }}
                  <template v-for="item in scope.row.goods_tag_pic">
                    <img :src="item" :key="item" style="margin-right: 5px" />
                  </template>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="goods_type_name"
              label="品类"
              width="200"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="goods_number_type"
              label="货品类型"
            >
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.goods_number_type === 10 ? "一码一货" : "一码多货"
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="warehouse_name"
              label="仓库"
              width="200"
            >
            </el-table-column>
            <el-table-column align="center" prop="sale_price" label="标签价">
            </el-table-column>
            <el-table-column
              align="center"
              prop="original_goods_weight"
              label="可盘总重(g)"
              width="160"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="practical_goods_weight"
              label="已盘总重(g)"
              width="160"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="original_gold_weight"
              label="可盘净金重(g)"
              width="180"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="original_total_num"
              label="可盘数量"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="practical_gold_weight"
              label="已盘净金重(g)"
              width="200"
            >
              <template slot-scope="scope">
                {{ scope.row.practical_gold_weight || 0 }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="practical_total_num"
              label="已盘数量"
            >
              <template slot-scope="scope">
                {{ scope.row.practical_total_num || 0 }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="gain_total_num"
              label="盈亏数量"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="gain_gold_weight"
              label="盈亏净金重(g)"
              width="180"
            >
            </el-table-column>

            <el-table-column
              align="center"
              prop="check_goods_result_text"
              label="盘点结果"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.check_goods_result_text === '已盘' ||
                    scope.row.check_goods_result_text === '盘盈'
                  "
                  style="color: #21bc36"
                >
                  {{ scope.row.check_goods_result_text }}
                </div>
                <div v-else>{{ scope.row.check_goods_result_text }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="checkName" label="盘点人">
            </el-table-column>
          </el-table>
          <div class="pagina">
            <el-pagination
              background
              :current-page="page"
              layout="total,sizes, prev, pager, next"
              :total="total"
              :page-size="$store.state.pageSizes[0]"
              :page-sizes="$store.state.pageSizes"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="不可盘点货品列表" name="20">
          <el-table :data="goodsTableList" stripe>
            <!-- <el-table-column align="center" prop="index" label="序号">
            </el-table-column> -->
            <el-table-column
              align="center"
              prop="goods_pic"
              label="图片"
              width="300"
            >
              <template v-slot="scope">
                <el-image
                  :src="scope.row.goods_pic"
                  :preview-src-list="[scope.row.goods_pic]"
                  class="image-slot"
                >
                  <div slot="error" class="image-slot">
                    <img :src="no_img" />
                  </div>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="goods_number" label="货号">
            </el-table-column>
            <el-table-column align="center" prop="goods_name" label="品名">
              <template slot-scope="scope">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  {{ scope.row.goods_name || "--" }}
                  <template v-for="item in scope.row.goods_tag_pic">
                    <img :src="item" :key="item" style="margin-right: 5px" />
                  </template>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="goods_type_name" label="品类">
            </el-table-column>
            <el-table-column
              align="center"
              prop="goods_number_type"
              label="货品类型"
            >
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.goods_number_type === 10 ? "一码一货" : "一码多货"
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="warehouse_name" label="仓库">
            </el-table-column
            ><el-table-column
              align="center"
              prop="original_gold_weight"
              label="不可盘净金重(g)"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="original_total_num"
              label="不可盘数量"
            >
            </el-table-column>
          </el-table>
          <div class="pagina">
            <el-pagination
              background
              :current-page="page"
              layout="total,sizes, prev, pager, next"
              :total="total"
              :page-size="$store.state.pageSizes[0]"
              :page-sizes="$store.state.pageSizes"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="操作日志" name="third">
          <div class="table">
            <el-table :data="logList.list" stripe>
              <template v-for="(item, index) in rowList2">
                <el-table-column
                  :key="index"
                  :label="item.label"
                  :prop="item.prop"
                  align="center"
                  :width="item.width"
                >
                  <template slot-scope="scope">
                    <div v-if="item.prop === 'msg'">
                      <div
                        v-html="scope.row.msg"
                        @click="handleGoods($event)"
                      ></div>
                    </div>
                    <div v-if="item.prop === 'create_time'">
                      {{ scope.row.date }}
                    </div>
                    <div v-if="item.prop === 'store_user_realname'">
                      {{ scope.row.store_user_realname }}
                    </div>
                  </template>
                </el-table-column>
              </template>
            </el-table>
          </div>
          <div class="pagina">
            <el-pagination
              background
              layout="total, prev, pager, next"
              :page-size="15"
              :total="logList.total"
              @current-change="handleSizeChange2"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <PrintConf
      function_code="checkPrint"
      @onRowList="handlePrintRow"
      v-show="false"
    />
    <LocalPrint
      :show="false"
      :printRows="printRows"
      :printData="printData"
      :title="title"
      :dialogVisible="localPrintVisible"
      @onClose="localPrintVisible = false"
    />
  </div>
</template>

<script>
import {
  getInfo,
  detailsList,
  lockGoodsList,
  checkLog,
  getTakingPrintReq,
} from "@/api/goods/goodsStock/goodsInventory/index.js";
import no_img from "../../../../../assets/images/no_img.png";
import { post } from "@/utils/request";
import { mixin } from "@/mixins/logs";
import PrintConf from "@/components/printConf/Index.vue";
import LocalPrint from "@/components/localPrint/Index.vue";

export default {
  mixins: [mixin],
  data() {
    return {
      title: "盘点单",
      localPrintVisible: false,
      printData: {},
      printRows: [], // 打印的行
      copyString: "",
      arr: [
        {
          name: "盘点单号",
          value: "",
          prop: "check_number",
        },
        {
          name: "盘点状态",
          value: "",
          prop: "check_status_text",
        },
        {
          name: "参与人",
          value: "",
          prop: "str_user_name",
        },
        {
          name: "可盘净金重(g)",
          value: "",
          prop: "original_gold_weight",
        },
        {
          name: "可盘数量(件)",
          value: "",
          prop: "original_total_num",
        },
        {
          name: "盘点结果",
          value: "",
          prop: "check_result_text",
        },
        {
          name: "已盘净金重(g)",
          value: "",
          prop: "practical_gold_weight",
        },
        {
          name: "已盘数量(件)",
          value: "",
          prop: "practical_total_num",
        },
        {
          name: "可盘总重(g)",
          value: "",
          prop: "original_goods_weight",
        },
        {
          name: "已盘总重(g)",
          value: "",
          prop: "practical_goods_weight",
        },
        {
          name: "可盘总标签价(元)",
          value: "",
          prop: "original_total_price",
        },
         {
          name: "已盘总标签价(元)",
          value: "",
          prop: "practical_total_price",
        },
        {
          name: "添加人",
          value: "",
          prop: "add_user_name",
        },
        {
          name: "添加时间",
          value: "",
          prop: "create_time",
        },
        {
          name: "审核人",
          value: "",
          prop: "audit_user_name",
        },
        {
          name: "审核时间",
          value: "",
          prop: "audit_time",
        },
        {
          name: "盘点范围",
          value: "",
          prop: "search_text",
        },
        {
          name: "备注",
          value: "",
          prop: "remark",
        },
      ],
      exportLoading: false,
      info: {},
      goodsTableList: [],
      soulGoodsList: [],
      tabPosition: 0,
      activeName: "10",
      no_img: no_img,
      allot_status: 0,
      goods_number: "",
      page: 1,
      page_num: 50,
      total: 0,
      lockGoodsList: [],
      lockTotal: 0,
      canPage: 1,
      logList: [],
      rowList2: [
        {
          label: "操作时间",
          prop: "create_time",
          width: 200,
        },
        {
          label: "操作记录",
          prop: "msg",
        },
        {
          label: "操作人",
          prop: "store_user_realname",
          width: 200,
        },
      ],
    };
  },
  components: {
    PrintConf,
    LocalPrint,
  },
  props: {
    check_id: Number,
  },
  created() {
    this.getInfo();
    this.detailsListInfo();
    this.handleCheckLog();
  },
  methods: {
    handlePrint() {
      this.getTakingPrint({
        check_id: this.check_id,
      });
    },
    getTakingPrint(cond) {
      getTakingPrintReq({ ...cond }).then((res) => {
        if (res.code === 1) {
          res.data.list.forEach((item, index) => {
            item.index = index + 1;
          });
          res.data.top = [];
          const data = res.data.data;
          const arr1 = [
            { txt: "创建人", value: data.add_user_name, width: "24%" },
            { txt: "创建时间", value: data.create_time, width: "24%" },
            { txt: "盘点单号", value: data.check_number, width: "30%" },
          ];
          res.data.bottom = [];
          const arr2 = [
            { txt: "备注", value: data.audit_remark, width: "100%" },
          ];
          const arr3 = [
            { txt: "审核状态", value: data.check_status_text, width: "24%" },
            { txt: "审核人", value: data.audit_user_name, width: "24%" },
            {
              txt: "审核时间",
              value: data.audit_time ? data.audit_time : "",
              width: "30%",
            },
          ];
          res.data.top.push(arr1);
          res.data.bottom.push(arr2, arr3);
          // 计算合计
          this.printData = res.data;
          const sum = {
            index: "合计",
          };
          const decimal = {};
          this.printData.list.forEach((item) => {
            this.printRows.forEach((row) => {
              if (row.is_sum) {
                if (!sum[row.field_alias]) {
                  sum[row.field_alias] = 0;
                }
                if (item[row.field_alias]) {
                  sum[row.field_alias] += Number(item[row.field_alias]);
                  if (!decimal[row.field_alias]) {
                    const arr = String(item[row.field_alias]).split(".");
                    if (arr.length === 2) {
                      decimal[row.field_alias] = arr[1].length;
                    } else {
                      decimal[row.field_alias] = 0;
                    }
                  }
                }
              }
            });
          });
          // 需要保留多少位小数
          Object.keys(sum).forEach((item) => {
            if (item !== "index") {
              sum[item] = sum[item].toFixed(decimal[item]);
            }
          });
          this.printData.list.push(sum);
          this.localPrintVisible = true;
        }
      });
    },
    handlePrintRow(rows) {
      this.printRows = rows;
    },
    copyCode(status) {
      detailsList({
        check_id: this.check_id,
        allot_status: this.allot_status,
        check_goods_result: status,
        goods_number: this.goods_number || 0,
        page: this.page,
        limit: this.page_num,
        is_check: this.activeName,
      }).then((res) => {
        if (res.code === 1) {
          if (res.data.list.length) {
            res.data.list.forEach((item) => {
              this.copyString += `${item.goods_number}\n`;
              this.$copyText(this.copyString);
            });
            this.$message.success("复制成功");
          } else {
            this.$message.warning("没有可复制的货号");
          }
        }
      });
    },
    /*
     *
     */
    getInfo() {
      getInfo(this.check_id).then((res) => {
        if (res.code === 1) {
          this.arr.forEach((item) => {
            item.value = res.data[item.prop] ? res.data[item.prop] : "--";
          });
          this.info = res.data;
        }
      });
    },
    handleTab(tab, event) {
      if (tab.paneName === "20") {
        this.goodsTableList = [];
        this.page = 1;
        this.page_num = 50;
        this.detailsListInfo();
      } else {
        this.goodsTableList = [];
        this.allot_status = 0;
        this.page = 1;
        this.page_num = 50;
        this.detailsListInfo();
      }
    },
    /*
     *  操作日志
     */
    handleCheckLog() {
      checkLog({ page: this.canPage, check_id: this.check_id }).then((res) => {
        this.logList = res.data;
      });
    },
    /*
     *  盘点单货品详情
     */
    detailsListInfo() {
      const data = {
        check_id: this.check_id,
        allot_status: this.allot_status,
        check_goods_result: this.tabPosition,
        goods_number: this.goods_number || 0,
        page: this.page,
        limit: this.page_num,
        is_check: this.activeName,
      };
      detailsList(data).then((res) => {
        if (res.code === 1) {
          res.data.list = res.data.list.map((item) => {
            item.isWeightRewind = false;
            item.isNumRewind = false;
            item.checkName = "";
            item.arr_tak_user.forEach((items) => {
              if (items.realname) {
                item.checkName += items.realname || "--";
              }
            });
            return item;
          });
          this.goodsTableList = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  导出操作
     */
    handleExport() {
      this.exportLoading = true;
      const exportExcel = () =>
        post({
          url: "store/goods.stock.Taking/exportExcel",
          data: { check_id: this.check_id },
        });
      exportExcel()
        .then((res) => {
          this.exportLoading = false;
          res = "\ufeff" + res;
          var blob = new Blob([res], { type: "application/vnd.ms-excel" });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, "盘点详情导出");
          } else {
            var urlCreator = window.URL || window.wibkitURL;
            var url = urlCreator.createObjectURL(blob);
            var a = document.createElement("a");
            a.download = "盘点详情导出";
            a.href = url;
            a.click();
          }
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    /*
     *  盘点锁定货品详情
     */
    lockGoodList() {
      lockGoodsList({ check_id: this.check_id, page: 1, limit: 15 }).then(
        (res) => {
          if (res.code === 1) {
            this.lockGoodsList = res.data.list;
            this.lockTotal = res.data.total;
          }
        }
      );
    },
    handleSizeChange2(val) {
      checkLog({ page: val }).then((res) => {
        this.logList = res.data;
      });
    },
    handleLockCurrentChange(val) {
      lockGoodsList({ check_id: this.check_id, page: val, limit: 15 }).then(
        (res) => {
          if (res.code === 1) {
            this.lockGoodsList = res.data.list;
            this.lockTotal = res.data.total;
          }
        }
      );
    },
    handleCurrentChange(val) {
      this.page = val;
      this.detailsListInfo();
    },
    handleSizeChange(val) {
      this.page = 1;
      this.page_num = val;
      this.detailsListInfo();
    },
    handleSearch() {
      this.page = 1;
      this.detailsListInfo();
    },
    /*
     *  返回
     */
    goBack() {
      this.$emit("hide");
    },
  },
};
</script>

<style lang="less" scoped>
.newly {
  padding: 20px;
}
.btn {
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px 0px 0px;
}
.top {
  display: flex;
  padding: 0px 50px 10px 50px;
  background: white;
  border-radius: 6px;
  flex-wrap: wrap;
  margin-top: 20px;
  .item {
    display: flex;
    margin-bottom: 20px;
    width: 33%;
    &-left {
      width: 140px;
      color: #909399;
      font-size: 16px;
      position: relative;
      &::before {
        content: "";
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: #2791ff;
        position: absolute;
        top: 10px;
        left: -15px;
      }
    }
    &-right {
      color: #565656;
      font-size: 16px;
    }
  }
}
.Intable {
  padding: 20px;
}
/deep/ .image-slot {
  width: 54px;
  height: 54px;
  margin: 5px auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
