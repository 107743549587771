<template>
  <div>
    <div class="content">
      <div class="card">
        <el-page-header @back="goBack" content="盘点审核"></el-page-header>
      </div>
    </div>
    <div class="newForm" style="padding-bottom: 20px">
      <div class="title">
        <div class="span"></div>
        盘点进度
      </div>
      <div class="cutLine"></div>
      <div class="speed">
        <div class="boxOne">
          <div style="width: 170px">
            <div class="speedNum">{{ info.gain_total_num || 0 }}</div>
            <div class="speedText">盈亏数量(件)</div>
          </div>
          <div class="line"></div>
          <div style="width: 170px">
            <div class="speedNum">{{ info.gain_gold_weight || 0 }}</div>
            <div class="speedText">盈亏净金重(g)</div>
          </div>
          <div class="line"></div>
          <div style="width: 170px">
            <div class="speedNum">{{ info.gain_total_price || 0 }}</div>
            <div class="speedText">盈亏总金额</div>
          </div>
        </div>
        <div class="boxTwo">
          <div style="width: 170px">
            <div class="speedNum">{{ info.original_total_num || 0 }}</div>
            <div class="speedText">可盘数量(件)</div>
          </div>
          <div class="line"></div>
          <div style="width: 170px">
            <div class="speedNum">{{ info.practical_total_num || 0 }}</div>
            <div class="speedText">已盘数量(件)</div>
          </div>
        </div>
        <div class="boxThree">
          <div style="width: 170px">
            <div class="speedNum">{{ info.original_gold_weight || 0 }}</div>
            <div class="speedText">可盘净金重(g)</div>
          </div>
          <div class="line"></div>
          <div style="width: 170px">
            <div class="speedNum">{{ info.practical_gold_weight || 0 }}</div>
            <div class="speedText">已盘净金重(g)</div>
          </div>
        </div>
        <!-- <div class="boxFive"></div>
        <div class="boxSix">
          <div class="speedNum">{{ info.not_count || 0 }}</div>
          <div class="speedText">待盘数量(件)</div>
        </div>
        <div class="boxSeven">
          <div class="speedNum">{{ info.not_gold_weight || 0 }}</div>
          <div class="speedText">待盘净金重(g)</div>
        </div> -->
        <div class="auditBut">
          <div>
            <el-button
              type="info"
              style="width: 116px"
              @click="handleAudit(50)"
              :loading="loading"
              >盘点不通过</el-button
            >
          </div>
          <div style="margin-top: 10px">
            <el-button
              type="primary"
              style="width: 116px"
              @click="handleAudit(40)"
              :loading="loading"
              >盘点通过</el-button
            >
          </div>
        </div>
      </div>
      <div style="color:#999;margin-top:20px;margin-left:30px">盘点范围：{{info.search_text}}</div>
    </div>
    <div class="newForm Intable">
      <el-tabs v-model="activeName" @tab-click="handleTab">
        <el-tab-pane label="可盘点货品列表" name="10">
          <el-radio-group
            v-model="tabPosition"
            style="margin-bottom: 30px"
            @change="detailsListInfo()"
          >
            <el-radio-button :label="0">全部</el-radio-button>
            <el-radio-button :label="20">已盘</el-radio-button>
            <el-radio-button :label="10">未盘</el-radio-button>
            <el-radio-button :label="40">盘盈</el-radio-button>
          </el-radio-group>
          <div style="float: right; margin-right: 20px">
            <el-input
              placeholder="请输入货号查询"
              clearable
              v-model="goods_number"
            >
              <template slot="append">
                <el-button type="primary" @click="handleSearch">搜索</el-button>
              </template>
            </el-input>
          </div>
          <el-table :data="goodsTableList" stripe :height="350">
            <el-table-column
              align="center"
              prop="goods_number"
              label="货号"
              width="200"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="goods_name"
              label="品名"
              width="200"
            >
              <template slot-scope="scope">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  {{ scope.row.goods_name || "--" }}
                  <template v-for="item in scope.row.goods_tag_pic">
                    <img :src="item" :key="item" style="margin-right: 5px" />
                  </template>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="goods_type_name"
              label="品类"
              width="200"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="goods_number_type"
              label="货品类型"
            >
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.goods_number_type === 10 ? "一码一货" : "一码多货"
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="warehouse_name"
              label="仓库"
              width="200"
            >
            </el-table-column>
            <el-table-column align="center" prop="sale_price" label="标签价">
            </el-table-column>
            <el-table-column
              align="center"
              prop="original_goods_weight"
              label="可盘总重(g)"
              width="160"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="practical_goods_weight"
              label="已盘总重(g)"
              width="160"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="original_gold_weight"
              label="可盘净金重(g)"
              width="180"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="original_total_num"
              label="可盘数量"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="practical_gold_weight"
              label="已盘净金重(g)"
              width="200"
            >
              <template slot-scope="scope">
                {{ scope.row.practical_gold_weight || 0 }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="practical_total_num"
              label="已盘数量"
            >
              <template slot-scope="scope">
                {{ scope.row.practical_total_num || 0 }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="gain_total_num"
              label="盈亏数量"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="gain_gold_weight"
              label="盈亏净金重(g)"
              width="180"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="check_goods_result_text"
              label="盘点结果"
            >
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.check_goods_result_text === '已盘' ||
                    scope.row.check_goods_result_text === '盘盈'
                  "
                  style="color: #21bc36"
                >
                  {{ scope.row.check_goods_result_text }}
                </div>
                <div v-else>{{ scope.row.check_goods_result_text }}</div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="checkName" label="盘点人">
            </el-table-column>
          </el-table>
          <div class="pagina">
            <el-pagination
              background
              :current-page="page"
              layout="total,sizes, prev, pager, next"
              :total="total"
              :page-size="$store.state.pageSizes[0]"
              :page-sizes="$store.state.pageSizes"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane label="不可盘点货品列表" name="20">
          <el-table :data="goodsTableList" stripe>
            <!-- <el-table-column align="center" prop="index" label="序号">
            </el-table-column> -->
            <el-table-column
              align="center"
              prop="goods_pic"
              label="图片"
              width="300"
            >
              <template v-slot="scope">
                <el-image
                  :src="scope.row.goods_pic"
                  :preview-src-list="[scope.row.goods_pic]"
                  class="image-slot"
                >
                  <div slot="error" class="image-slot">
                    <img :src="no_img" />
                  </div>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="goods_number" label="货号">
            </el-table-column>
            <el-table-column align="center" prop="goods_name" label="品名">
              <template slot-scope="scope">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  {{ scope.row.goods_name || "--" }}
                  <template v-for="item in scope.row.goods_tag_pic">
                    <img :src="item" :key="item" style="margin-right: 5px" />
                  </template>
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="goods_type_name" label="品类">
            </el-table-column>
            <el-table-column
              align="center"
              prop="goods_number_type"
              label="货品类型"
            >
              <template slot-scope="scope">
                <div>
                  {{
                    scope.row.goods_number_type === 10 ? "一码一货" : "一码多货"
                  }}
                </div>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="warehouse_name" label="仓库">
            </el-table-column
            ><el-table-column
              align="center"
              prop="original_gold_weight"
              label="不可盘金重(g)"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="original_total_num"
              label="不可盘数量"
            >
            </el-table-column>
          </el-table>
          <div class="pagina">
            <el-pagination
              :current-page="page"
              layout="total,sizes, prev, pager, next"
              :total="total"
              :page-size="$store.state.pageSizes[0]"
              :page-sizes="$store.state.pageSizes"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog
      :visible.sync="auditVisiable"
      width="30%"
      title="审核不通过原因"
      :destroy-on-close="true"
      :before-close="cancelAudit"
    >
      <el-form
        ref="auditForm"
        :model="auditForm"
        :rules="rules"
        label-width="80px"
      >
        <el-form-item label="原因说明" prop="remark">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入审核不通过原因说明"
            v-model="auditForm.remark"
          ></el-input>
        </el-form-item>
        <div style="text-align: right">
          <el-button type="primary" @click="submitAudit('auditForm')"
            >提交</el-button
          >
          <el-button @click="cancelAudit">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getInfo,
  applyBy,
  detailsList,
  audit,
  lockGoodsList,
  newTakingList,
} from "@/api/goods/goodsStock/goodsInventory/index.js";
import no_img from "../../../../../assets/images/no_img.png";

export default {
  data() {
    return {
      loading: false,
      info: {},
      goodsTableList: [],
      page: 1,
      total: 0,
      page_num: 50,
      auditForm: {},
      activeName: "10",
      tabPosition: 0,
      auditVisiable: false,
      auditStatus: 0,
      no_img: no_img,
      allot_status: 0,
      goods_number: "",
      lockGoodsList: [],
      lockTotal: 0,
      rules: {
        remark: [
          {
            required: true,
            message: "请填写审核不通过原因",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: {
    check_id: Number,
  },
  created() {
    this.getInfo();
    this.detailsListInfo();
  },
  methods: {
    /*
     *  查询盘点单详情
     */
    getInfo() {
      getInfo(this.check_id).then((res) => {
        if (res.code === 1) {
          this.info = res.data;
        }
      });
    },
    /*
     *  盘点单货品详情
     */
    detailsListInfo() {
      const data = {
        check_id: this.check_id,
        allot_status: this.allot_status,
        check_goods_result: this.tabPosition,
        goods_number: this.goods_number || 0,
        page: this.page,
        limit: this.page_num,
        is_check: this.activeName,
      };
      detailsList(data).then((res) => {
        if (res.code === 1) {
          res.data.list = res.data.list.map((item) => {
            item.isWeightRewind = false;
            item.isNumRewind = false;
            item.checkName = "";
            item.arr_tak_user.forEach((items) => {
              if (items.realname) {
                item.checkName += items.realname || "--";
              }
            });
            return item;
          });
          this.goodsTableList = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    /*
     *  盘点锁定货品详情
     */
    lockGoodList() {
      lockGoodsList({ check_id: this.check_id, page: 1, limit: 15 }).then(
        (res) => {
          if (res.code === 1) {
            this.lockGoodsList = res.data.list;
            this.lockTotal = res.data.total;
          }
        }
      );
    },
    handleLockCurrentChange(val) {
      lockGoodsList({ check_id: this.check_id, page: val, limit: 15 }).then(
        (res) => {
          if (res.code === 1) {
            this.lockGoodsList = res.data.list;
            this.lockTotal = res.data.total;
          }
        }
      );
    },
    /*
     *  提交审核失败原因
     */
    submitAudit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.commitAudit();
        }
      });
    },
    handleTab(tab, event) {
      if (tab.paneName === "20") {
        this.goodsTableList = [];
        this.page = 1;
        this.page_num = 50;
        this.detailsListInfo();
      } else {
        this.goodsTableList = [];
        this.page = 1;
        this.page_num = 50;
        this.allot_status = 0;
        this.detailsListInfo();
      }
    },
    cancelAudit() {
      this.auditVisiable = false;
    },
    /*
     *  返回
     */
    goBack() {
      this.$emit("hide");
    },
    handleCurrentChange(val) {
      this.page = val;
      this.detailsListInfo();
    },
    handleSizeChange(val) {
      this.page_num = val;
      this.page = 1;
      this.detailsListInfo();
    },
    handleSearch() {
      this.detailsListInfo();
    },
    /*
     *  审核盘点单
     */
    handleAudit(status) {
      this.auditStatus = status;
      if (status === 50) {
        this.auditVisiable = true;
      } else {
        this.$confirm("确定盘点通过吗?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.commitAudit();
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消",
            });
          });
      }
    },
    /*
     *  提交审核
     */
    commitAudit(status) {
      this.loading = true;
      const data = {
        check_id: this.check_id,
        check_status: this.auditStatus,
        audit_remark: this.auditForm.remark,
      };
      audit(data).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "提交成功!",
          });
          this.loading = false;
          this.goBack();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .title {
  display: flex;
  flex-direction: row;
  padding-left: 30px;
  height: 60px;
  line-height: 60px;

  .span {
    margin: auto 10px auto 0;
    width: 4px;
    height: 16px;
    background: #5493f5;
    border-radius: 2px;
  }
}
/deep/ .newly {
  padding: 20px;
}

/deep/ .cutLine {
  height: 1px;
  background: #f5f6fa;
}

.speed {
  margin-left: 30px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.boxOne {
  display: flex;
  margin-right: 30px;
  width: 530px;
  height: 90px;
  background: linear-gradient(90deg, #e570ee, #9264f6);
  border-radius: 6px;
  color: white;
  text-align: center;
}

.line {
  margin: auto 0;
  width: 2px;
  height: 39px;
  background: #f5f6fa;
}

.boxTwo {
  display: flex;
  margin-right: 30px;

  width: 300px;
  height: 90px;
  background: linear-gradient(90deg, #5aabfe, #597bf7);
  border-radius: 6px;
  color: white;
  text-align: center;
}

.boxThree {
  display: flex;
  margin-right: 20px;
  width: 300px;
  height: 90px;
  background: linear-gradient(90deg, #5aabfe, #597bf7);
  border-radius: 6px;
  color: white;
  text-align: center;
}

.boxFour {
  margin-right: 10px;
  width: 170px;
  height: 90px;
  background: linear-gradient(90deg, #fd7e5d, #ff628a);
  border-radius: 6px;
  color: white;
  text-align: center;
}

.boxFive {
  margin-right: 20px;
  width: 170px;
  height: 90px;
  background: linear-gradient(90deg, #fd7e5d, #ff628a);
  border-radius: 6px;
  color: white;
  text-align: center;
}

.boxSix {
  margin-right: 10px;
  width: 170px;
  height: 90px;
  background: linear-gradient(
    90deg,
    rgba(14, 205, 186, 0.98),
    rgba(0, 190, 244, 0.98)
  );
  border-radius: 6px;
  color: white;
  text-align: center;
}

.boxSeven {
  width: 170px;
  height: 90px;
  background: linear-gradient(
    90deg,
    rgba(14, 205, 186, 0.98),
    rgba(0, 190, 244, 0.98)
  );
  border-radius: 6px;
  color: white;
  text-align: center;
}

.speedNum {
  margin-top: 15px;
  font-size: 24px;
  font-family: Microsoft YaHei;
  font-weight: bold;
}
.speedText {
  font-size: 14px;
}

.auditBut {
  margin-left: 20px;
}
.Intable {
  padding: 20px;
}
/deep/ .image-slot {
  width: 54px;
  height: 54px;
  margin: 5px auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
